<template>
  <div class="editor-wrapper" v-loading="!isRender">
    <div class="editor">
      <Editor
        v-if="isRender"
        style="height: 98%"
        :editorId="editorId"
        :defaultConfig="{ readOnly: true }"
        :defaultHtml="defaultHtml"
        mode="default"
      />
    </div>
  </div>
</template>
<script>
import '@wangeditor/editor/dist/css/style.css'
import { Editor, getEditor, removeEditor } from '@wangeditor/editor-for-vue'
export default {
  components: {  Editor  },
  data() {
    return {
      isLoading: false,
      treeSelect: {
        query: false, // 查看 详情
      },
      editorId: 'wangEditor-1',
      defaultHtml: '<h1 style="text-align: center;">用户协议</h1>',
      renderHtml: null,
      isRender: false
    }
  },
  created() {
  },
  mounted() {
    this.getEditorText();
  },
  beforeDestroy() {
    this.beforeDestroy()
  },
  methods: {
    // 请求获取富文本HTML
    getEditorText() {
      this.$curl.get('/system/agreement/details/3').then((res) => {
        if(res.data) { this.defaultHtml = res.data.text }
        this.isRender = true
      })
    },

    beforeDestroy() {
      const editor = getEditor(this.editorId)
      if (editor == null) return
      editor.destroy()
      removeEditor(this.editorId)
    }
  }
}
</script>

<style lang="scss" scoped>
.editor-wrapper {
  overflow: auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  .editor {
    width: 100vw;
    //min-width: 700px;
    //max-width: 1000px;
  }

  ::v-deep .w-e-scroll, #w-e-textarea-1 {
    padding: 0 15vw !important;
  }
}
</style>
